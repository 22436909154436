import React from "react";
import pokemon from "../pokemon.json";
import { Modal } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";

export default function Generate ({ letter }) {
    letter = letter.current.value.toLowerCase();
    if (!["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"].includes(letter) && letter !== "") {
        return (
            <>
                <Modal.Header>Error</Modal.Header>
                <Modal.Body>{letter} is not a valid letter.</Modal.Body>
            </>
        );
    }
    const [rule] = useLocalStorage("rule");
    const validPokemon = [];
    pokemon.forEach(element => {
        const newLetter = letter || element[0];
        let valid = true;
        const [rule] = useLocalStorage("rule");
        const ruleL1 = rule.rule[1];
        if (ruleL1 === "letter") {
            if (element[0] !== newLetter) valid = false;
        } else if (ruleL1 instanceof Array) {
            if (!ruleL1.includes(element[0])) valid = false;
        }
        const ruleL2 = rule.rule[2];
        if (ruleL2 === "letter") {
            if (element[1] !== newLetter) valid = false;
        } else if (ruleL2 instanceof Array) {
            if (!ruleL2.includes(element[1])) valid = false;
        }
        const ruleL3 = rule.rule[3];
        if (ruleL3 === "letter") {
            if (element[2] !== newLetter) valid = false;
        } else if (ruleL3 instanceof Array) {
            if (!ruleL3.includes(element[2])) valid = false;
        }
        const ruleLast = rule.rule["-1"];
        if (ruleLast === "letter") {
            if (element[element.length - 1] !== newLetter) valid = false;
        } else if (ruleLast instanceof Array) {
            if (!ruleLast.includes(element[element.length - 1])) valid = false;
        }
        if (valid) validPokemon.push(element);
    });
    return (
        <>
            <Modal.Header>Valid pokemon</Modal.Header>
            <Modal.Body>
                {validPokemon.length ? `All valid pokemon for the rule ${rule.name} ${letter ? `that start with ${letter}` : ""} are ${validPokemon.join(", ")}.` : `There are no valid pokemon for the rule ${rule.name}${letter ? ` that start with ${letter}` : ""}.`}
            </Modal.Body>
        </>
    );
}
