import React, { useState, useEffect } from "react";
import Main from "./Main";
import Sidebar from "./Sidebar";

export default function Dashboard () {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange () {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = (width <= 768);
    if (isMobile) {
        return (
            <div className="d-flex" style={{ height: "100vh", width: "80vh" }}>
                <Sidebar/>
                <Main/>
            </div>
        );
    } else {
        return (
            <div className="d-flex">
                <Sidebar/>
                <Main/>
            </div>
        );
    }
}
