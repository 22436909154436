import React from "react";
import { Modal } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";

export default function Validate ({ pokemon }) {
    pokemon = pokemon.current.value;
    const value = pokemon[0];
    let valid = true;
    const [rule] = useLocalStorage("rule");
    const ruleL2 = rule.rule[2];
    if (ruleL2 === "letter") {
        if (pokemon[1] !== value) valid = false;
    } else if (ruleL2 instanceof Array) {
        if (!ruleL2.includes(pokemon[1])) valid = false;
    }
    const ruleL3 = rule.rule[3];
    if (ruleL3 === "letter") {
        if (pokemon[2] !== value) valid = false;
    } else if (ruleL3 instanceof Array) {
        if (!ruleL3.includes(pokemon[2])) valid = false;
    }
    const ruleLast = rule.rule["-1"];
    if (ruleLast === "letter") {
        if (pokemon[pokemon.length - 1] !== value) valid = false;
    } else if (ruleLast instanceof Array) {
        if (!ruleLast.includes(pokemon[pokemon.length - 1])) valid = false;
    }
    return (
        <>
            <Modal.Header>Validated Successfully!</Modal.Header>
            <Modal.Body>
                {valid ? `${pokemon} is valid for the rule ${rule.name}!` : `${pokemon} is not valid for the rule ${rule.name}`}
            </Modal.Body>
        </>
    );
}
