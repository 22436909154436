import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";

export default function AddRule ({ closeModal }) {
    const errorRef = useRef();
    const nameRef = useRef();
    const firstRef = useRef();
    const secondRef = useRef();
    const thirdRef = useRef();
    const lastRef = useRef();
    const [options, setOptions] = useLocalStorage("options", [
        {
            name: "ABC",
            rule: {
                1: "letter",
                2: "*",
                3: "*",
                "-1": "*"
            }
        },
        {
            name: "ABZ",
            rule: {
                1: "letter",
                2: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        },
        {
            name: "ACZ",
            rule: {
                1: "letter",
                2: ["f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        }
    ]);

    function createRule (name, first, second, third, last) {
        if (second.includes(", ")) second = second.split(", ");
        if (third.includes(", ")) third = third.split(", ");
        if (last.includes(", ")) last = last.split(", ");

        setOptions(options => [
            ...options,
            {
                name,
                rule: {
                    1: first,
                    2: second,
                    3: third,
                    "-1": last
                }
            }
        ]);
    }

    function handleSubmit (e) {
        if (options.map(element => element.name).includes(nameRef.current.value)) {
            e.preventDefault();
            errorRef.current.innerText = `${nameRef.current.value} already exists. Please choose a different name.`;
            return;
        }

        createRule(nameRef.current.value, firstRef.current.value, secondRef.current.value || "*", thirdRef.current.value || "*", lastRef.current.value || "*");

        closeModal();
    }
    return (
        <>
            <Modal.Header closeButton>Add Rule</Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <span ref={errorRef} style={{ color: "red" }}></span>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control maxLength="30" type="text" ref={nameRef} required/>
                    </Form.Group>
                    <span>Put in a list of letters separated by <code>, </code> or put in <code>*</code> for any letter or put in <code>letter</code> for the first level.</span>
                    <Form.Group>
                        <Form.Label>First</Form.Label>
                        <Form.Control defaultValue="letter" type="text" ref={firstRef} readOnly/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Second</Form.Label>
                        <Form.Control type="text" ref={secondRef}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Third</Form.Label>
                        <Form.Control type="text" ref={thirdRef}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last</Form.Label>
                        <Form.Control type="text" ref={lastRef}/>
                    </Form.Group>
                    <Button type="submit">Create</Button>
                </Form>
            </Modal.Body>
        </>
    );
}
