import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";

export default function EditRule ({ closeModal, option }) {
    const nameRef = useRef();
    const firstRef = useRef();
    const secondRef = useRef();
    const thirdRef = useRef();
    const lastRef = useRef();
    const setOptions = useLocalStorage("options", [
        {
            name: "ABC",
            rule: {
                1: "letter",
                2: "*",
                3: "*",
                "-1": "*"
            }
        },
        {
            name: "ABZ",
            rule: {
                1: "letter",
                2: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        },
        {
            name: "ACZ",
            rule: {
                1: "letter",
                2: ["f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        }
    ])[1];

    function editRule (name, first, second, third, last) {
        if (second.includes(", ")) second = second.split(", ");
        if (third.includes(", ")) third = third.split(", ");
        if (last.includes(", ")) last = last.split(", ");

        setOptions(options => {
            return options.map(option => {
                if (option.name === nameRef.current.value) {
                    return {
                        name,
                        rule: {
                            1: first,
                            2: second,
                            3: third,
                            "-1": last
                        }
                    };
                }
                return option;
            });
        });
    }

    function handleSubmit (e) {
        editRule(nameRef.current.value, firstRef.current.value, secondRef.current.value || "*", thirdRef.current.value || "*", lastRef.current.value || "*");

        closeModal();
    }

    return (
        <>
            <Modal.Header closeButton>Edit Rule</Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control defaultValue={option.name} ref={nameRef} readOnly/>
                    </Form.Group>
                    <span>Put in a list of letters separated by <code>, </code> or put in <code>*</code> for any letter or put in <code>letter</code> for the first level.</span>
                    <Form.Group>
                        <Form.Label>First</Form.Label>
                        <Form.Control defaultValue={option.rule[1] instanceof Array ? option.rule[1].join(", ") : option.rule[1]} type="text" ref={firstRef} readOnly/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Second</Form.Label>
                        <Form.Control defaultValue={option.rule[2] instanceof Array ? option.rule[2].join(", ") : option.rule[2]} type="text" ref={secondRef}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Third</Form.Label>
                        <Form.Control defaultValue={option.rule[3] instanceof Array ? option.rule[3].join(", ") : option.rule[3]} type="text" ref={thirdRef}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Last</Form.Label>
                        <Form.Control defaultValue={option.rule["-1"] instanceof Array ? option.rule["-1"].join(", ") : option.rule["-1"]} type="text" ref={lastRef}/>
                    </Form.Group>
                    <Button type="submit">Update</Button>
                    <Button variant="secondary" onClick={() => {
                        setOptions(options => options.filter(element => element.name !== nameRef.current.value));
                        closeModal();
                        window.location.reload();
                    }}>Delete</Button>
                </Form>
            </Modal.Body>
        </>
    );
}
