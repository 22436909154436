import React, { useRef } from "react";
import { FormControl, InputGroup } from "react-bootstrap";

export default function SearchRules ({ options, button }) {
    const searchRef = useRef();

    function handleSearch () {
        const input = searchRef.current.value.toLowerCase();
        const current = options.current;
        const items = Array.from(current.getElementsByTagName("div"));
        items.forEach(element => {
            if (element.innerText.toLowerCase().startsWith(input)) {
                element.style.display = "";
            } else {
                element.style.display = "none";
            }
        });
    }

    return (
        <InputGroup>
            <FormControl style={{ width: "150px" }} onKeyUp={handleSearch} type="text" ref={searchRef} placeholder="Search for rules..."/>
            {button}
        </InputGroup>
    );
}
