import React, { useRef, useState } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import useLocalStorage from "./useLocalStorage";
import AddRule from "./AddRule";
import EditRule from "./EditRule";
import SearchRules from "./SearchRules";

export default function Sidebar () {
    const currentRef = useRef();
    const rulesRef = useRef();
    const sidebarRef = useRef();
    const [addRuleModalOpen, setAddRuleModalOpen] = useState(false);
    const [options] = useLocalStorage("options", [
        {
            name: "ABC",
            rule: {
                1: "letter",
                2: "*",
                3: "*",
                "-1": "*"
            }
        },
        {
            name: "ABZ",
            rule: {
                1: "letter",
                2: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        },
        {
            name: "ACZ",
            rule: {
                1: "letter",
                2: ["f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u"],
                3: ["n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
                "-1": "*"
            }
        }
    ]);
    const [rule, setRule] = useLocalStorage("rule", options[0]);
    function updateCurrentRule () {
        setTimeout(() => {
            const rule = JSON.parse(localStorage.getItem("pokemon-abc-rule"));
            currentRef.current.innerHTML = `Current rule is ${rule.name}`;
        }, 10);
    }
    function addRuleCloseModal () {
        setAddRuleModalOpen(false);
    }

    const optionsEditList = [];
    options.forEach((option, index) => {
        optionsEditList.push({});
        [optionsEditList[index].editRuleModalOpen, optionsEditList[index].setEditRuleModalOpen] = useState(false);

        optionsEditList[index].editRuleCloseModal = function editRuleCloseModal () {
            optionsEditList[index].setEditRuleModalOpen(false);
        };
    });

    return (
        <>
            <div ref={sidebarRef} className="d-flex flex-column border-right overflow-auto flex-grow-1">
                <p ref={currentRef}>Current rule is {rule.name}</p>
                <SearchRules options={rulesRef} button={<Button style={{ width: "50px" }} variant="light" onClick={() => setAddRuleModalOpen(true)}>+</Button>}/>
                <Modal show={addRuleModalOpen} onHide={addRuleCloseModal}>
                    <AddRule closeModal={addRuleCloseModal}/>
                </Modal>
                <ListGroup ref={rulesRef}>
                    {options.map((option, index) => (
                        <ListGroup.Item key={option.name}>
                            <Button variant="light" onClick={() => {
                                setRule(_ => option);
                                updateCurrentRule();
                            }}>{option.name}</Button>
                            <Button variant="light" onClick={() => optionsEditList[index].setEditRuleModalOpen(true)}>Edit</Button>
                            <Modal show={optionsEditList[index].editRuleModalOpen} onHide={optionsEditList[index].editRuleCloseModal}>
                                <EditRule closeModal={optionsEditList[index].editRuleCloseModal} option={option} />
                            </Modal>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </>
    );
}
