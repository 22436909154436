import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import Generate from "./Generate";
import Validate from "./Validate";
import pokemon from "../pokemon.json";

export default function Main () {
    const generateLetterRef = useRef();
    const validatePokemonRef = useRef();
    const [validateModalOpen, setValidateModalOpen] = useState(false);
    const [generateModalOpen, setGenerateModalOpen] = useState(false);

    function handleGenerateSubmit (e) {
        e.preventDefault();
        setGenerateModalOpen(true);
    }

    function generateCloseModal () {
        setGenerateModalOpen(false);
    }

    function handleValidateSubmit (e) {
        e.preventDefault();
        setValidateModalOpen(true);
    }

    function validateCloseModal () {
        setValidateModalOpen(false);
    }

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange () {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = (width <= 768);

    return (
        <div className="d-flex flex-column flex-grow-1">
            <Tabs>
                <Tab eventKey="generate" title="Generate Valid Pokemon">
                    <Form onSubmit={handleGenerateSubmit}>
                        <Form.Group>
                            <Form.Label>{isMobile ? "Letter \n (Generate pokemon that just \n start with this letter. \nMake it blank for all letters.)" : "Letter (Generate pokemon that just start with this letter. Make it blank for all letters.)"}</Form.Label>
                            <Form.Control ref={generateLetterRef} maxLength="1"></Form.Control>
                        </Form.Group>
                        <Button type="submit">Generate</Button>
                    </Form>
                    <Modal show={generateModalOpen} onHide={generateCloseModal}>
                        <Generate letter={generateLetterRef}/>
                    </Modal>
                </Tab>
                <Tab eventKey="validate" title="Validate">
                    <Form onSubmit={handleValidateSubmit}>
                        <Form.Group>
                            <Form.Label>Pokemon</Form.Label>
                            <Form.Control as="select" ref={validatePokemonRef}>
                                {pokemon.sort().map(element => <option key={element}>{element}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Button type="submit">Validate</Button>
                    </Form>
                    <Modal show={validateModalOpen} onHide={validateCloseModal}>
                        <Validate pokemon={validatePokemonRef}/>
                    </Modal>
                </Tab>
            </Tabs>
        </div>
    );
}
